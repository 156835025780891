import React from "react";
import "./Checkbox.scss";

export default function Checkbox({
    name,
    id,
    type = "checkbox",
    label = false,
    placeholder = "",
    defaultValue = "",
    register,
    errors = false,
    errorMessage = "",
    value = "",
    ...props
}) {

  return (
    <div className="checkbox-container">
      <input
      className={`checkbox ${errors && "invalid"} ${errors.length}`}
      name={name}
      id={id}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      ref={register}
      {...props}
     
      />
      {label && (
      <label className={`checkbox-label`} htmlFor={id}>
        {label}
      </label>
    

  )}
  {errorMessage && errors && (
    <div className="checkbox-error">
      <span>{errorMessage}</span>
    </div>
  )}
    </div>
  );
}
