import * as yup from "yup";

export const schema = {
  name: {
    name: "name",
    id: "contact-name",
    type: "text",
    label: "Name",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  phone: {
    name: "phone",
    id: "contact-phone",
    type: "text",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  emailFrom: {
    name: "emailFrom",
    id: "contact-emailFrom",
    type: "email",
    label: "Email",
    placeholder: "Enter Email",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().email().required(),
  },

  address: {
    name: "address",
    id: "contact-address",
    type: "text",
    label: "Address",
    placeholder: "Enter Address",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  knowPersonalInfo:{
    name:"knowPersonalInfo",
    id:"know-personal-info",
    type:"checkbox",
    label:"Right to Know Personal Information You’ve collected about me",
    defaultValue: "",
    errorMessage: "",
    
  },
  deletePersonalInfo:{
    name:"deletePersonalInfo",
    id:"delete-personal-info",
    type:"checkbox",
    label:"Right to Delete non-exempt Personal Information",
    defaultValue: "",
    errorMessage: "",
   
  },
  salesPersonalInfo:{
    name:"salesPersonalInfo",
    id:"prohibit-sales-personal-info",
    type:"checkbox",
    label:"Right to Prohibit Sales of My Personal Information",
    defaultValue: "",
    errorMessage: "",

  },

};

const schemaShape = Object.keys(schema).reduce((result, key) => {
  result[key] = schema[key].validation;
  return result;
}, {});



export const resolver = yup.object().shape(schemaShape);

export default {
  schema,
  resolver
};
