import React from "react";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import JumbotronCard from "components/JumbotronCard";
import backgroundOverlay from "assets/images/header_skew/faq_header.jpg";
import DoNotSellMyInfoForm from "components/forms/DoNotSellMyInfoForm";

import ScrollToTop from "components/ScrollToTop";

export default function DoNotSellMyInformationPage() {
  const title = "Do Not Sell My Information";
  const url = "do-not-sell";

  return (
    <Main page={url} pagetitle={title}>
      <HeaderSkew
        title={title}
        subtitle=""
        url="faq"
        background={backgroundOverlay}
        backgroundColor="small-do-not-sell"
        appearance="grid-gray"
      >
        <JumbotronCard type="form">
          <DoNotSellMyInfoForm emailTo="consumercomplaints@loanpal.com" />
        </JumbotronCard>
      </HeaderSkew>
      <Block layer="1" name="" guides={1} appearance="grid-do-not-sell" />
      <ScrollToTop modifier={["skewed--white"]} guides={1} />
    </Main>
  );
}
