import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";
import Checkbox from "components/forms/Checkbox";
import { schema, resolver } from "data/form-schemas/do-not-sell-my-info.js";
import { sendContactForm } from "services/index.js";

// // import "./ContactForm.scss";

export default function DoNotSellMyInfoForm({
  emailTo = "consumercomplaints@loanpal.com",
  emailToCopy = false,
}) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({
    name,
    phone,
    emailFrom,
    address,
    knowPersonalInfo,
    deletePersonalInfo,
    salesPersonalInfo,
  }) => {
    setSubmited(true);

    const formData = {
      emailTo: emailTo,
      emailFrom: emailFrom,
      subject: `Loanpal consumer do not sell my information from ${name}`,
      name: name,
      phone: phone,
      address: address,
      rightToKnowInformationYouHaveCollectedAboutMe: knowPersonalInfo
        ? "[x]"
        : "[]",
      rightToDeleteNonExemptPersonalInformation: deletePersonalInfo
        ? "[x]"
        : "[]",
      desiredResrightToProhibitSalesOfMyPersonalInformationolution: salesPersonalInfo
        ? "[x]"
        : "[]",
    };

    try {
      const response = await sendContactForm(formData);
      if (!emailToCopy) {
        setFormStatus("SENT");
      }
    } catch (err) {
      setFormStatus("ERROR");
    }

    if (emailToCopy) {
      try {
        const response = await sendContactForm({
          ...formData,
          emailTo: emailToCopy,
        });
        setFormStatus("SENT");
      } catch (err) {
        setFormStatus("ERROR");
      }
    }

    setSubmited(false);
  };

  return (
    <div className="form-do-not-sell">
      {formStatus === "SENT" && <FormSuccess type="do-not-sell" />}

      {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

      {formStatus === "WAIT" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Please complete the form below.</h2>

          <p className="disclaimer">
            Required fields <span>*</span>
          </p>

          <InputText
            {...schema.name}
            register={register}
            errors={errors["name"]}
            value={watch("name")}
            isRequired={schema["name"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.phone}
            register={register}
            errors={errors["phone"]}
            value={watch("phone")}
            isRequired={schema["phone"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.emailFrom}
            register={register}
            errors={errors["emailFrom"]}
            value={watch("emailFrom")}
            isRequired={schema["emailFrom"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.address}
            register={register}
            errors={errors["address"]}
            value={watch("address")}
            isRequired={schema["address"].validation.exclusiveTests.required}
          />

          <Checkbox
            {...schema.knowPersonalInfo}
            register={register}
            errors={errors["knowPersonalInfo"]}
            value={watch("knowPersonalInfo")}
          />

          <Checkbox
            {...schema.deletePersonalInfo}
            register={register}
            errors={errors["deletePersonalInfo"]}
            value={watch("deletePersonalInfo")}
          />

          <Checkbox
            {...schema.salesPersonalInfo}
            register={register}
            errors={errors["salesPersonalInfo"]}
            value={watch("salesPersonalInfo")}
          />
          <FormLegals alternative />

          <FormSubmit data-emailto={emailTo} disabled={submited} />
        </form>
      )}
    </div>
  );
}
